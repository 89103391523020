import * as yup from "yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProgressCheckRecommendation } from "./ProgressCheckRecommendation.tsx";
import { Select } from "../UI/Select.tsx";
import { InputWithLabel } from "../UI/InputWithLabel.tsx";
import { RatingInput } from "../UI/RatingInput.tsx";
import { convertToInteger } from "../DataUtils/convertToInteger.ts";
import { ProgressCheckUserDto } from "../gql/graphql.ts";
import { CheckBoxGroup } from "../UI/CheckBox/CheckBoxGroup.tsx";
import { TextAreaInput } from "../UI/TextAreaInput.tsx";
import { PrimaryButton } from "../UI/PrimaryButton.tsx";
import { useWithLoading } from "../UI/Loading/useWithLoading.ts";
import { useEffect } from "react";
import { getRecommendationLabel } from "./getRecommendationLabel.tsx";

export type ProgressCheckFormInput = {
  accuracy?: number;
  comprehension?: number;
  vocabularyRange?: number;
  fluency?: number;
  recommendation?: ProgressCheckRecommendation[];
  notes?: string;
  noShow: boolean;
  passed?: boolean;
};

const valuationError = "Specify a valuation from 1 to 5";
const specifyAnIntegerValueError = "Specify an integer value";

type Props = {
  defaultValues?: ProgressCheckFormInput;
  saveButtonLabel: string;
  onSave: (data: ProgressCheckFormInput) => void;
  onChange: (data: ProgressCheckFormInput) => void;
  participant: ProgressCheckUserDto;
  disabledValues?: Partial<
    Record<
      keyof ProgressCheckFormInput,
      {
        disabled: boolean;
        reason?: string;
      }
    >
  >;
};

export function ProgressCheckForm({
  defaultValues,
  saveButtonLabel,
  onSave,
  onChange,
  participant,
  disabledValues,
}: Props) {
  const { withLoading, loading } = useWithLoading();
  const schema = yup.object({
    noShow: yup.boolean().required(),
    passed: yup.boolean().when("noShow", {
      is: false,
      then: (yup) => yup.required("Please select a valid value"),
    }),

    accuracy: yup.number().when("noShow", {
      is: false,
      then: (yup) =>
        yup
          .integer(specifyAnIntegerValueError)
          .min(1, valuationError)
          .max(5, valuationError)
          .required(),
    }),
    comprehension: yup.number().when("noShow", {
      is: false,
      then: (yup) =>
        yup
          .integer(specifyAnIntegerValueError)
          .min(1, valuationError)
          .max(5, valuationError)
          .required(),
    }),
    vocabularyRange: yup.number().when("noShow", {
      is: false,
      then: (yup) =>
        yup
          .integer(specifyAnIntegerValueError)
          .min(1, valuationError)
          .max(5, valuationError)
          .required(),
    }),
    fluency: yup.number().when("noShow", {
      is: false,
      then: (yup) =>
        yup
          .integer(specifyAnIntegerValueError)
          .min(1, valuationError)
          .max(5, valuationError)
          .required(),
    }),
    notes: yup.string(),
    recommendation: yup.array().when("noShow", {
      is: false,
      then: (y) =>
        y
          .of(
            yup
              .string()
              .defined()
              .oneOf(
                Object.values(ProgressCheckRecommendation),
                "Invalid recommendation",
              ),
          )
          .required()
          .when("passed", {
            is: false,
            then: (schema) => {
              return schema.min(
                1,
                "You should select at least one recommendation for a user who does not pass the progress check",
              );
            },
          }),
    }),
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch,
    setValue,
    trigger,
  } = useForm<ProgressCheckFormInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...(defaultValues ?? {}),
      noShow: false,
    },
  });

  const input = watch();
  useEffect(() => {
    onChange(input);
  }, [input, onChange]);

  const onSubmit: SubmitHandler<ProgressCheckFormInput> = async (data) => {
    await withLoading(async () => onSave(data));
  };

  async function triggerValidation() {
    await trigger("comprehension");
    await trigger("accuracy");
    await trigger("vocabularyRange");
    await trigger("fluency");
    await trigger("notes");
    await trigger("recommendation");
    await trigger("passed");
  }

  return (
    <form className={"space-y-8"}>
      <h3 className={""}>
        Assessment for {participant.givenName} {participant.familyName}
      </h3>
      <InputWithLabel label={"Did they show up?"}>
        <Controller
          control={control}
          render={({ field }) => {
            return (
              <Select<boolean>
                value={field.value}
                options={[
                  {
                    name: "Yes",
                    id: false,
                  },
                  {
                    name: "No",
                    id: true,
                  },
                ]}
                onChange={async (v) => {
                  field.onChange(v);
                  if (v) {
                    setValue("comprehension", 0);
                    setValue("vocabularyRange", 0);
                    setValue("notes", "");
                    setValue("fluency", 0);
                    setValue("accuracy", 0);
                    setValue("recommendation", []);
                    setValue("passed", false);
                    await triggerValidation();
                  }
                }}
              />
            );
          }}
          name={"noShow"}
        />
      </InputWithLabel>
      <InputWithLabel
        subLabel={
          "If their comprehension, vocabulary, fluency and accuracy are all where they should be for the level that they are in and you think that they are ready for more of a challenge, then you can suggest that they move onto the next level. Remember: we don't want to move them to the next level unless we think they are going to be successful. The goal is to set them up for success."
        }
        label={"Are they ready for the next level?"}
      >
        <Controller
          control={control}
          name={"passed"}
          disabled={input.noShow}
          render={({ field }) => {
            return (
              <Select<boolean>
                value={field.value}
                errorMessage={errors.passed?.message}
                disabled={
                  disabledValues?.["passed"]?.disabled || field.disabled
                }
                disabledReason={
                  field.disabled ? "" : disabledValues?.["passed"]?.reason
                }
                options={[
                  {
                    name: "No",
                    id: false,
                  },
                  {
                    name: "Yes",
                    id: true,
                  },
                ]}
                onChange={async (v) => {
                  field.onChange(v);
                  await triggerValidation();
                }}
              />
            );
          }}
        />
      </InputWithLabel>
      <InputWithLabel label={"Accuracy"}>
        <Controller
          control={control}
          disabled={input.noShow}
          render={({ field }) => {
            return (
              <RatingInput
                disabled={field.disabled}
                errorMessage={errors.accuracy?.message}
                value={field.value ? convertToInteger(field.value) : 0}
                onChange={async (v) => {
                  field.onChange(v);
                  await triggerValidation();
                }}
              />
            );
          }}
          name={"accuracy"}
        />
      </InputWithLabel>
      <InputWithLabel label={"Comprehension"}>
        <Controller
          control={control}
          disabled={input.noShow}
          render={({ field }) => {
            return (
              <RatingInput
                disabled={field.disabled}
                errorMessage={errors.comprehension?.message}
                value={field.value ? convertToInteger(field.value) : 0}
                onChange={async (v) => {
                  field.onChange(v);
                  await triggerValidation();
                }}
              />
            );
          }}
          name={"comprehension"}
        />
      </InputWithLabel>
      <InputWithLabel label={"Vocabulary Range"}>
        <Controller
          control={control}
          disabled={input.noShow}
          render={({ field }) => {
            return (
              <RatingInput
                disabled={field.disabled}
                errorMessage={errors.vocabularyRange?.message}
                value={field.value ? convertToInteger(field.value) : 0}
                onChange={async (v) => {
                  field.onChange(v);
                  await triggerValidation();
                }}
              />
            );
          }}
          name={"vocabularyRange"}
        />
      </InputWithLabel>
      <InputWithLabel label={"Fluency"}>
        <Controller
          control={control}
          disabled={input.noShow}
          render={({ field }) => {
            return (
              <RatingInput
                disabled={field.disabled}
                errorMessage={errors.fluency?.message}
                value={field.value ? convertToInteger(field.value) : 0}
                onChange={async (v) => {
                  field.onChange(v);
                  await triggerValidation();
                }}
              />
            );
          }}
          name={"fluency"}
        />
      </InputWithLabel>
      <InputWithLabel label={"Areas of Improvement"}>
        <Controller
          control={control}
          disabled={input.noShow}
          render={({ field }) => {
            return (
              <CheckBoxGroup<ProgressCheckRecommendation>
                disabled={field.disabled}
                errorMessage={errors.recommendation?.message}
                items={Object.values(ProgressCheckRecommendation).map((r) => {
                  return {
                    label: getRecommendationLabel(r),
                    id: r,
                    value: field.value?.includes(r) ?? false,
                  };
                })}
                onChange={async (i) => {
                  field.onChange(i.filter((i) => i.value).map((i) => i.id));
                  await triggerValidation();
                }}
              />
            );
          }}
          name={"recommendation"}
        />
      </InputWithLabel>
      <InputWithLabel label={"Notes"}>
        <TextAreaInput disabled={input.noShow} {...register("notes")} />
      </InputWithLabel>
      <div className={"flex justify-center"}>
        <div className={"flex items-center space-x-4"}>
          <PrimaryButton
            onClick={handleSubmit(onSubmit)}
            loading={loading}
            label={saveButtonLabel}
          />
        </div>
      </div>
    </form>
  );
}
